@use '../../Assets/css/var';

*,
*::before,
*::after {
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

@font-face {
    font-family: 'defiworld-Regular';
    src: url('../fonts/defiworld-Regular.eot');
    src: url('../fonts/defiworld-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/defiworld-Regular.woff2') format('woff2'),
        url('../fonts/defiworld-Regular.woff') format('woff'),
        url('../fonts/defiworld-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "defiworld-Bold";
    src: url('../fonts/defiworld-Bold.eot');
    src: url('../fonts/defiworld-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/defiworld-Bold.woff2') format('woff2'),
        url('../fonts/defiworld-Bold.woff') format('woff'),
        url('../fonts/defiworld-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "defiworld-Bold1";
    src: url('../fonts/defiworld-Bold1.eot');
    src: url('../fonts/defiworld-Bold1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/defiworld-Bold1.woff2') format('woff2'),
        url('../fonts/defiworld-Bold1.woff') format('woff'),
        url('../fonts/defiworld-Bold1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'defiworld-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    color: var.$colour-white;
    text-align: left;
    background-color: #000000;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(../images/bgglass.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -2;
        opacity: 0;
    }

    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/backgif.gif);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -3;
        transform: scale(1.1);
        filter: hue-rotate(30deg) brightness(0.5);
        opacity: 0.35;
    }
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed to center vertically */
  }
  .loader {
    width: var(--loader-size);
    height: var(--loader-size);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    img{
        max-width: 200px;
    }
  }
 
 
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: var.$colour-theme-dark;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var.$colour-theme;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background-color: var.$colour-theme-dark;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'defiworld-Bold';
    line-height: 1;
    color: #ffffff;
}

img {
    max-width: 100%;
    height: auto;
}

section {
    margin-bottom: 100px;
    padding-bottom: 1px;
    padding-top: 5px;
    position: relative;
    overflow: hidden;

    section {
        margin-bottom: 0px;
    }
}

a {
    color: var.$colour-white;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        color: var.$colour-theme;
    }
}

.container {
    max-width: 1320px;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
}

.title {
    text-align: center;
    padding: 0;
    margin: 0px 0px 40px;
    border: none;

    h2 {
        text-align: center;
        display: block;
        margin: 0px;
        color: var.$colour-theme;
        font-size: 28px;
        text-transform: capitalize;
    }
}

.textgames {
    text-align: center;
h3{
    color: #50dbff;
    padding-top: 10px;
    
}
}

.buttonGrp {
    text-align: left;
    // margin-top: 40px;
    margin-left: -7.5px;
    margin-right: -7.5px;

}

.defi_btn {
    align-items: center;
      background-image: url(../images//btnbg.svg);
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;
      display: inline-flex;
      font-size: 15px;
      font-weight: 800;
      justify-content: center;
      letter-spacing: 0.8px;
      min-height: 91px;
      min-width: 180px;
      overflow: hidden;
      padding: 10px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      z-index: 1;
      background-position: center;
      text-decoration: none;
      background-color: transparent;
      box-shadow: none;
      border: none;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 3px;
    //     left: 50%;
    //     width: 80%;
    //     height: 3px;
    //     background-image: linear-gradient(to right, transparent, rgba(60, 115, 169, 0.561), #3c73a9, #37f8ff, #3c73a9, rgba(60, 115, 169, 0.561), transparent);
    //     transform: translateX(-50%);
    // }

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        box-sizing: border-box;
        transition: all 0.3s;
    
    }

    &:hover {
        opacity: 0.8;
        color: #08e5ff !important;
    }
}

// Title

.titlebox    {
    text-align: center;
    max-width: 500px;
    margin: 0px auto 80px;
    position: relative;


    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 60%;
        width: 100%;
        height: 90px;
        background-image: url(../../Assets/images/titleframe.svg);
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 9;
        filter: hue-rotate(40deg);
        animation: blinkers 1.5s linear infinite;
        filter: drop-shadow(0 0 2px #50DBFF) hue-rotate(40deg);
        transition: 0.9s;
    }
    @keyframes blinkers {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }

    &::before {
        left: 100%;
        transform: translate(5%, -50%);
    }

    &::after {
        right: 100%;
        transform: translate(-5%, -50%) scaleX(-1);
    }

    &.titleboxNft {
        max-width: 600px;
        margin: 0px auto 10px;

        h2 {
            margin-bottom: 10px;
        }

        &::before,
        &::after {
            display: none;
        }
    }

    h6 {
        color: var.$colour-theme;
        margin-bottom: 15px;
        font-size: 20px;
    }

    h2 {
        font-size: 37px;
        margin-bottom: 25px;
        line-height: 1;
        text-shadow: 2px 2px 50px #50dbff;
    }
}

// Social Media
.socialIcons {
    margin-top: 30px;
    margin-bottom: 20px;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;

        li {
            margin: 0px 8px;

            a {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border: 1px solid var.$colour-theme-dark;
            }

            svg {
                fill: var.$colour-theme;
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 10px;
            }
        }
    }
}

#my-scrollbar {
    width: 500px;
    height: 500px;
    overflow: auto;
}
.tokemonicsec {
    display: flex;
    flex-wrap: nowrap;
}
// .imageBoxChart{
//     width: 100%;
//     height: auto;
// }
.color-changing-element {
    max-width: 700px;
    mix-blend-mode: lighten;
}
.chatDefiMain {
    .chatDefi {
        max-height: 600px;
        max-width: 700px;
    }

    .tokenomicsTable {
        max-width: 800px;
        margin: 0 auto;
        padding: 0px;
        margin-top: 80px;

        table {
            width: 100%;

            .defi_btn_cst {
                text-align: center;
                display: inline-block;
                border: 1px solid #35f8ff;
                padding: 8px 15px;
                border-radius: 4px;
                color: #35f8ff;
                background-color: #153048;
                margin-left: 15px;
                margin-right: -5px;
                margin-top: 10px;
                margin-bottom: 5px;
                font-size: 18px;
            }

            th {
                padding: 10px 15px;
                background-color: #153048;
                font-weight: 500;
                font-size: 28px;
                text-align: center;
                color: #35f8ff;
                font-family: "defiworld-Bold1";
            }

            td {
                padding: 15px 0px;
                font-weight: 500;
                font-size: 16px;
                border-bottom: 1px solid #1c3b59;
            }
            a.linktable {
                color: #35f8ff;
            }
            tr {
                &:nth-last-child(1) {
                    td {
                        border: none;
                    }
                }

                td:nth-child(even) {
                    text-align: right
                }

            }

        }
    }
}

.buttonMainbanner {
    position: relative;

    &>.row {
        max-width: 1100px;
        margin: 0px auto;
        padding: 20px 10px 30px;
    }

    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(33, 78, 122, 0.31);
        transform: scale(1) perspective(300px) rotateX(-5deg);
        border-radius: 14px;
        z-index: -1;
        border: 1px solid #50dbff;
    }

    a,
    .roadInfobx {
        text-decoration: none;
        color: #50dbff;
        position: relative;
        text-align: center;
        line-height: 1.2;
        // font-family: "defiworld-Bold1";

        &:hover {
            color: #ffffff;
        }

        img {
            mix-blend-mode: lighten;
            -webkit-filter: grayscale(10) brightness(1.5);
            filter: grayscale(10) brightness(1.5);
            border-radius: 50%;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            text-align: center;
            line-height: 1.1;
            min-height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .descrNftStaking {
        padding: 10px;

        p {
            text-align: center
        }
    }

    p {
        margin: 10px auto;
        color: #ffffff;
        text-align: center;
    }
}

.nftStaking {
    .buttonMainbanner {
        padding-top: 20px;

        &>.row {
            padding: 20px 10px 80px;
        }
    }
}

.roadmap {
    .buttonMainbanner {
        padding-top: 20px;

        &>.row {
            padding: 0px 10px 80px;
            margin-top: 0px;
        }
    }
}

.nftvideoSec {
    mix-blend-mode: lighten;

    video {
        max-width: 500px !important;
    }
}




// comingsoon page css //

.carditems {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 20px;
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-wrap: wrap;
}
.card {
  
background: transparent;
    max-width: 250px;
}
.gamesbtn{
    margin: 0 auto;
}
.gamesimg{
    border: 1px solid #50dbff;
    border-radius: 10px;
    padding: 2px;
}
.americantext{
    font-size: 13px;
}
.lablesection {
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    height: 150px;
    left: -10px;
    overflow: hidden;
    top: -10px;
    width: 150px;
    z-index: 20;
}
 .lablesection:before {
    align-items: center;
    background-color: transparent;
    background-image: linear-gradient(90deg,#6bfcff 0,#153048);
    color: #fff;
    content: "";
    display: flex;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    letter-spacing: .1em;
    position: absolute;
    text-transform: uppercase;
    transform: rotate(-45deg) translateY(-20px);
    width: 150%;
}
.lablesection:after {
    background-image: linear-gradient(90deg,#6bfcff 0,#153048);
    bottom: 0;
    box-shadow: 140px -140px rgb(95, 73, 38);  
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    width: 11px;
    z-index: -1;
}
.label {
    position: absolute;
    height: 30px;
    left: -17%;
    top: 30%;
    transform: rotate(-45deg);
    width: 170px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "defiworld-Bold";

}

// Media Query Css

@import 'media';