// Footer Css Start
footer {
    text-align: center;
    padding: 30px 15px;
    background-image: linear-gradient(transparent, #00090d);
    position: relative;

    &::before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 170px;
        background-image: url(../../Assets/images/header-frame.svg);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        transform: scaleY(-1);
    }

    .copyright {
        text-align: center;

        p {
            margin: 0px;
            font-size: 14px;
        }
    }

    .socialIcons {
        ul {
            list-style: none;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 575px) {
    footer ul li {
        margin: 0px 3px;
    }

    footer ul li a {
        width: 35px;
        height: 35px;
    }

    footer ul li svg {
        padding: 7px;
    }

    footer .socialIcons ul {
        padding: 0;
        margin: 0px;
    }

    footer .socialIcons ul li {
        margin: 0 2px 8px;
    }

    footer .socialIcons ul li a {
        height: 32px;
        width: 32px;
    }

    footer .socialIcons ul li svg {
        padding: 7px;
    }

    footer .socialIcons {
        margin-bottom: 12px;
    }
}

// Footer Css End