@use '../../Assets/css/var';

.roadmap {
    margin: 0 auto 60px;

    .roadMapBox {
        .col-12 {
            margin-bottom: 30px;
        }

        .innerInfoBox {
            backdrop-filter: blur(25px);
            background-color: rgba(0, 0, 0, 0.12);
            border: 1px solid #005d5f;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            padding: 25px;
            position: relative;
            height: 100%;

            &.disabled {
                opacity: 0.5;
            }

            &::before {
                content: "";
                position: absolute;
                top: -6px;
                left: -6px;
                background-image: url('../../Assets/images/borderLeft.svg');
                background-position: left top;
                background-repeat: no-repeat;
                background-size: contain;
                width: 45px;
                height: 50px;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -4px;
                right: -4px;
                background-image: url('../../Assets/images/borderRight.svg');
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: contain;
                width: 70px;
                height: 45px;
            }
        }

        p {
            margin-bottom: 10px;
            font-size: 18px;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }

            b {
                color: #4fff4f;
            }
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #01ffff;
            font-size: 90px;
            font-weight: 700;
            z-index: -1;
            opacity: 0.1;
            width: 100%;
            text-align: center;
        }
    }
}