@use '../../Assets/css/var';

.aboutsec {
    .infobox {

        h2 {
            margin: 0 0 30px;
            font-size: 62px;
            font-weight: bold;
            line-height: 1;
        }
    }

    .imageBox {
        position: relative;
        max-width: 540px;
        margin: 0 auto;
        text-align: center;


        .defiWorldIcon {
            &.ui2 {
                width: 100%;
                max-width: 500px;
            }
        }

    }

    .leftinfoFaq {
        h6 {
            color: var.$colour-theme;
            margin-bottom: 15px;
            font-size: 20px;
        }

        h2 {
            font-size: 62px;
            margin-bottom: 15px;
            line-height: 1;
        }
    }

    .abputCirBx {
        max-width: 700px;

        .centerlogo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
            color: #ffffff;
        }

        .innerbox {
            h2 {
                margin-top: 20px;
                font-weight: 400;
                font-size: 24px;
            }
        }
    }

    // Dashboard Css Start
    .cirLeftToRight {
        transform-origin: center;
        animation: keyLeftToRight 5s ease-in-out infinite alternate-reverse;
    }

    .cirLeftToRightR {
        transform-origin: center;
        animation: keyLeftToRight 10s ease-in-out infinite alternate;
    }

    .cirLeftToRightRS {
        transform-origin: center;
        animation: keyLeftToRight 20s ease-in-out infinite alternate;
    }

    @keyframes keyLeftToRight {
        to {
            transform: rotate(360deg);
        }
    }

    .cirRightToLeft {
        transform-origin: center;
        animation: keyRightToLeft 15s linear infinite;
    }

    @keyframes keyRightToLeft {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .righttoleft {
        transform-origin: center;
        animation: righttoleft 6s linear infinite reverse;
    }

    @keyframes righttoleft {
        to {
            transform: rotate(-360deg);
        }
    }

    .lefttoright {
        transform-origin: center;
        animation: righttoleft 6s linear infinite;
    }

    @keyframes lefttoright {
        to {
            transform: rotate(-360deg);
        }
    }

    .circleLine {
        position: absolute;
        bottom: -30px;
        left: -30px;
        width: 90px;
        height: 90px;
        background-color: #182133;
        border-radius: 50%;
        animation: circleLine 12s linear infinite;

    }

    @keyframes circleLine {
        to {
            transform: rotate(360deg);
        }
    }
}

@media only screen and (max-width: 575px) {
    .abtbtn2{
        margin-top: 0px !important;
    }
}