@use '../../Assets/css/var';

.ox_banner {
    margin: 0px 0px 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 200px;

    .infobox {
        margin-bottom: 15px;
        text-shadow: 2px 2px 50px #50dbff;
       

        h1 {
            margin: 0 0 30px;
            font-size: 62px;
            font-weight: bold;
            line-height: 1;
        }

        ul {
            padding: 0px;
            margin: 0px 0px 30px;
            list-style: none;

            li {
                margin-bottom: 15px;
            }
        }

        h3 {
            font-size: 20px;
            margin: 0 0 10px;
        }

        .quickPlayTitle {
            margin: 40px 0px 30px;
            font-size: 30px;
        }

        .buttonGrp.headGrpBtn {
            margin-top: 5px;
        }
    }

    .imageBox {
        position: relative;
        max-width: 540px;
        margin: 0 auto;
        text-align: center;
      
     
        }

    

    }
    video.bannervideo {
        width: 100%;
        height: auto;
        mix-blend-mode: exclusion;

    }
    .countDownMuMain {
        text-align: center;
        margin-top: 20px;

        h2 {
            font-size: 32px;
        }

        .countDownMu {
            display: inline-block;
            padding: 6px 10px;
            font-size: 26px;
            border-radius: 4px;
            min-width: 220px;
            margin-top: 10px;
            border: 1px solid #01ffff;
            color: #01ffff;

            b {
                display: inline-block;
                margin: 0px 5px;
            }
        }
    }


.smartContractBx {
    display: flex;
    align-items: center;

    label {
        display: block;
        margin-right: 10px;
    }

    a {
        display: inline-block;
        color: #01ffff !important;
        font-size: 16px;
        text-decoration: underline;
        font-weight: 600;
        letter-spacing: 1px;
    }

    img {
        width: auto;
        filter: invert(1);
        margin-left: 10px;
        height: 20px;
        max-width: 20px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 767px) {
    .ox_banner {
        text-align: center;
        padding-top: 90px;
    }

    .smartContractBx {
        justify-content: center;
    }

    .ox_banner .infobox ul {
        justify-content: center;
    }

    .ox_banner .imageBox svg {
        max-height: 70vh;
        width: 100%;
    }

    .ox_banner .imageBox .defiWorldIcon {
        max-width: 100px;
    }
}

@media only screen and (max-width: 575px) {
    .smartContractBx a {
        font-size: 14px;
        letter-spacing: 0.5px;
    }

    .smartContractBx {
        font-size: 16px;
    }

    .smartContractBx img {
        height: 18px;
        max-width: 18px;
    }
}