.aboutsec .infobox h2 {
  margin: 0 0 30px;
  font-size: 62px;
  font-weight: bold;
  line-height: 1;
}
.aboutsec .imageBox {
  position: relative;
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
}
.aboutsec .imageBox .defiWorldIcon.ui2 {
  width: 100%;
  max-width: 500px;
}
.aboutsec .leftinfoFaq h6 {
  color: #50dbff;
  margin-bottom: 15px;
  font-size: 20px;
}
.aboutsec .leftinfoFaq h2 {
  font-size: 62px;
  margin-bottom: 15px;
  line-height: 1;
}
.aboutsec .abputCirBx {
  max-width: 700px;
}
.aboutsec .abputCirBx .centerlogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #ffffff;
}
.aboutsec .abputCirBx .innerbox h2 {
  margin-top: 20px;
  font-weight: 400;
  font-size: 24px;
}
.aboutsec .cirLeftToRight {
  transform-origin: center;
  animation: keyLeftToRight 5s ease-in-out infinite alternate-reverse;
}
.aboutsec .cirLeftToRightR {
  transform-origin: center;
  animation: keyLeftToRight 10s ease-in-out infinite alternate;
}
.aboutsec .cirLeftToRightRS {
  transform-origin: center;
  animation: keyLeftToRight 20s ease-in-out infinite alternate;
}
@keyframes keyLeftToRight {
  to {
    transform: rotate(360deg);
  }
}
.aboutsec .cirRightToLeft {
  transform-origin: center;
  animation: keyRightToLeft 15s linear infinite;
}
@keyframes keyRightToLeft {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.aboutsec .righttoleft {
  transform-origin: center;
  animation: righttoleft 6s linear infinite reverse;
}
@keyframes righttoleft {
  to {
    transform: rotate(-360deg);
  }
}
.aboutsec .lefttoright {
  transform-origin: center;
  animation: righttoleft 6s linear infinite;
}
@keyframes lefttoright {
  to {
    transform: rotate(-360deg);
  }
}
.aboutsec .circleLine {
  position: absolute;
  bottom: -30px;
  left: -30px;
  width: 90px;
  height: 90px;
  background-color: #182133;
  border-radius: 50%;
  animation: circleLine 12s linear infinite;
}
@keyframes circleLine {
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 575px) {
  .abtbtn2 {
    margin-top: 0px !important;
  }
}/*# sourceMappingURL=Aboutsec.css.map */