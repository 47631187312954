.ox_banner {
  margin: 0px 0px 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 200px;
}
.ox_banner .infobox {
  margin-bottom: 15px;
  text-shadow: 2px 2px 50px #50dbff;
}
.ox_banner .infobox h1 {
  margin: 0 0 30px;
  font-size: 62px;
  font-weight: bold;
  line-height: 1;
}
.ox_banner .infobox ul {
  padding: 0px;
  margin: 0px 0px 30px;
  list-style: none;
}
.ox_banner .infobox ul li {
  margin-bottom: 15px;
}
.ox_banner .infobox h3 {
  font-size: 20px;
  margin: 0 0 10px;
}
.ox_banner .infobox .quickPlayTitle {
  margin: 40px 0px 30px;
  font-size: 30px;
}
.ox_banner .infobox .buttonGrp.headGrpBtn {
  margin-top: 5px;
}
.ox_banner .imageBox {
  position: relative;
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
}

video.bannervideo {
  width: 100%;
  height: auto;
  mix-blend-mode: exclusion;
}

.countDownMuMain {
  text-align: center;
  margin-top: 20px;
}
.countDownMuMain h2 {
  font-size: 32px;
}
.countDownMuMain .countDownMu {
  display: inline-block;
  padding: 6px 10px;
  font-size: 26px;
  border-radius: 4px;
  min-width: 220px;
  margin-top: 10px;
  border: 1px solid #01ffff;
  color: #01ffff;
}
.countDownMuMain .countDownMu b {
  display: inline-block;
  margin: 0px 5px;
}

.smartContractBx {
  display: flex;
  align-items: center;
}
.smartContractBx label {
  display: block;
  margin-right: 10px;
}
.smartContractBx a {
  display: inline-block;
  color: #01ffff !important;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
  letter-spacing: 1px;
}
.smartContractBx img {
  width: auto;
  filter: invert(1);
  margin-left: 10px;
  height: 20px;
  max-width: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .ox_banner {
    text-align: center;
    padding-top: 90px;
  }
  .smartContractBx {
    justify-content: center;
  }
  .ox_banner .infobox ul {
    justify-content: center;
  }
  .ox_banner .imageBox svg {
    max-height: 70vh;
    width: 100%;
  }
  .ox_banner .imageBox .defiWorldIcon {
    max-width: 100px;
  }
}
@media only screen and (max-width: 575px) {
  .smartContractBx a {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  .smartContractBx {
    font-size: 16px;
  }
  .smartContractBx img {
    height: 18px;
    max-width: 18px;
  }
}/*# sourceMappingURL=Banner.css.map */