.advantages .imageBox img {
  width: 100%;
  margin: 0 auto;
  mix-blend-mode: exclusion;
  filter: brightness(1.3);
  max-width: 420px;
  transform: scale(1.2);
  transform-origin: bottom;
}
.advantages .leftinfoFaq p {
  position: relative;
}
.advantages .leftinfoFaq span {
  font-family: "defiworld-Bold1";
  display: inline-block;
  letter-spacing: 2px;
  margin-right: 5px;
  color: #50dbff;
}
.advantages .leftinfoFaq b {
  color: #50dbff;
  display: inline-block;
  margin-right: 5px;
  font-weight: 400;
}/*# sourceMappingURL=Advantages.css.map */