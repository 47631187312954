@media only screen and (max-width: 1024px) {

.imageBoxChart {
  margin: 0 auto;
}

  .tokemonicsec {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 50px; */
}
}


@media only screen and (max-width: 992px) {
  .ox_banner .infobox h1 {
    font-size: 42px;
  }

  .headGrpBtn {
    display: block;
    max-width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 30px;
  }

  .container {
    max-width: calc(100% - 15px);
  }

  .ox_banner .infobox h1 {
    font-size: 32px;
  }

  .aboutsec .infobox h2 {
    font-size: 32px;
    text-align: center;
  }

  .advantages .leftinfoFaq p {
    text-align: justify;
  }

  .infobox p,
  .nftStaking .buttonMainbanner .descrNftStaking p {
    text-align: justify;
  }

  .titlebox h2 {
    font-size: 32px;
  }

  .titlebox {
    margin: 0px auto 40px;
  }

  .advantages .imageBox img {
    filter: brightness(1);
    transform: scale(1);
  }

  .aboutsec .imageBox img,
  .features .imageBox img {
    max-width: 100%;
  }

  .headGrpBtn {
    margin-left: auto;
    margin-right: auto;
  }

  .ox_banner {
    margin: 0px 0px 50px;
  }

  section {
    margin-bottom: 50px;
  }
  .chatDefiMain .chatDefi {
    max-width: 500px;
}
}

@media only screen and (max-width: 575px) {
  .balancebox li span {
    width: 100%;
    display: block;
    padding: 8px 6px;
  }

  .balancebox li:nth-last-child(1) {
    margin-left: 6px;
  }

  .hamburger {
    width: 25px !important;
  }

  .chatDefiMain .tokenomicsTable {
    padding: 0px;
  }

  .chatDefiMain .tokenomicsTable table td {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 1.2;
  }

  .chatDefiMain .tokenomicsTable table {
    tr {
      &:nth-last-child(1) {
        text-align: left;

        td {
          display: revert;

          button {
            text-align: center;
            padding: 10px 5px;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 3px;
            margin-bottom: 3px;
            width: 100%;
            font-size: 16px;
            max-width: 150px;
          }
        }
      }
    }
  }

  .buttonMainbanner a img,
  .buttonMainbanner .roadInfobx img {
    transform: scale(1.2);
  }

  .buttonMainbanner a span,
  .buttonMainbanner .roadInfobx span {
    font-size: 20px;
    width: 100%;
    max-width: 90px;
  }

  .buttonMainbanner p {
    margin: 20px auto;
    font-size: 14px;
    min-height: 79px;
  }

  .buttonMainbanner::before {
    transform: scale(1) perspective(200px) rotateX(-0.7deg);
    background-color: #000000;
    border-color: #0adeff;
  }

  .roadmap .buttonMainbanner>.row {
    padding: 0px 10px 0px;
    margin: 0 auto;
  }

  .buttonMainbanner>.row {
    margin: 20px auto 0px;
    padding: 20px 10px 0px;
    margin-left: -20px;
  }

  footer::before {
    height: 120px;
  }

  footer {
    padding: 10px 15px 10px;
  }
  .chatDefiMain .chatDefi {
    max-width:500px;
}
}
@media only screen and (max-width: 425px) {
  .chatDefiMain .chatDefi {
    max-width: 300px;
}

.defi_btn {
    min-width: 155px;
}
}